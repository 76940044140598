"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createTableExportName = exports.createExportName = void 0;
const is_defined_1 = require("../is-defined");
const createExportName = (name, wsName, periodStart, periodEnd, extension) => ([name.replace(' ', '_'), wsName.replace(' ', '_'), periodStart.format('YYYY-MM-DD'), periodEnd.format('YYYY-MM-DD'), 'Dayswaps'].join('_') + extension);
exports.createExportName = createExportName;
const createTableExportName = (config) => {
    var _a, _b;
    return ([
        config.tableName.replace(/\s/g, '_'),
        config.workspaceName.replace(/\s/g, '_'),
        (0, is_defined_1.isDefined)(config.extraSeparator) ? (_a = config.extra) === null || _a === void 0 ? void 0 : _a.call(config).replace(config.extraSeparator, '_') : (_b = config.extra) === null || _b === void 0 ? void 0 : _b.call(config),
        'Dayswaps'
    ].filter(is_defined_1.isDefined).join('_'));
};
exports.createTableExportName = createTableExportName;
