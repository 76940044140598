import React, { useState } from 'react'
import { t } from 'i18next'
import PropTypes from 'prop-types'
import { Button, Input, Dropdown } from '@ui'
import { Modal } from '@ui/modal'
import './index.scss'

import connect from './connect'

const ACTION_PARAMETER_TYPES = {
  TEXT: 'text',
  NUMBER: 'number',
  TIME: 'time',
  DATE: 'date',
  OPTIONS_LIST: 'optionsList',
  NONE: 'none'
}

const BatchActions = ({
  close,
  records,
  actions
}) => {
  const [isExecutingAction, setIsExecutingAction] = useState(false)
  const [selectedActionIdx, setSelectedActionIdx] = useState(0)
  const [selectedActionParameter, setSelectedActionParameter] = useState([])

  if (!actions || !actions.length) return null
  const action = actions[selectedActionIdx]
  return (
    <Modal
      className='ds-batch-actions-modal'
      size={Modal.SIZES.S}
      footerContent={
        <>
          <Button
            onClick={close}
            label={t('CLOSE')}
          />
          <Button
            onClick={async () => {
              await setIsExecutingAction(true)
              await actions[selectedActionIdx].actionFunction(records, selectedActionParameter)
              await setIsExecutingAction(false)
              close()
            }}
            disabled={actions[selectedActionIdx].isDisabled(records, selectedActionParameter) || isExecutingAction}
            loading={isExecutingAction}
            label={t('SAVE')}
            style={Button.STYLES.CONTAINED}
          />
        </>
      }
      headerContent={<div className='ds-title'>{t('BATCH_ACTIONS_TITLE_X', { x: records.length })}</div>}
      sections={[
        <>
          <Dropdown
            label={t('BATCH_ACTIONS_SELECT_ACTION')}
            singleSelect
            sortedOptions={false}
            size={Dropdown.SIZES.FULL_WIDTH}
            type={Dropdown.TYPES.VARIABLE}
            style={Dropdown.STYLES.LIGHT}
            options={actions.map((ac, acIdx) => {
              return {
                value: acIdx,
                label: ac.actionLabel
              }
            })}
            value={[{ value: selectedActionIdx, label: actions[selectedActionIdx].actionLabel }]}
            placeholder={t('BATCH_ACTIONS_SELECT')}
            onChange={(v) => {
              setSelectedActionIdx(v.value)
              setSelectedActionParameter(null)
            }}
          />

          {action.actionParameterType === ACTION_PARAMETER_TYPES.OPTIONS_LIST
            ? (
              <Dropdown
                label={action.actionParameterLabel}
                singleSelect={!action.enableMultiselect}
                searchable
                sortedOptions={false}
                size={Dropdown.SIZES.FULL_WIDTH}
                type={Dropdown.TYPES.VARIABLE}
                style={Dropdown.STYLES.LIGHT}
                options={action.actionParameterListOptions}
                value={!action.enableMultiselect ? [action.actionParameterListOptions.find(opt => opt.value === selectedActionParameter)].filter(Boolean) : selectedActionParameter}
                placeholder={t('BATCH_ACTIONS_SELECT')}
                onChange={(v) => {
                  if (!action.enableMultiselect) {
                    return setSelectedActionParameter(v.value)
                  }
                  return setSelectedActionParameter(v)
                }}
              />
            )
            : action.actionParameterType === ACTION_PARAMETER_TYPES.NONE
              ? null
              : (
                <Input
                  type={action.actionParameterType}
                  label={action.actionParameterLabel}
                  size={Input.SIZES.FULL_WIDTH}
                  value={selectedActionParameter}
                  onChange={setSelectedActionParameter}
                />
              )}
        </>
      ]}
    />
  )
}

BatchActions.propTypes = {
  close: PropTypes.func.isRequired,
  records: PropTypes.array.isRequired,
  actions: PropTypes.arrayOf(
    PropTypes.shape({
      // name of the action, displayed in the action type dropdown
      actionLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
      // function that's called when the action is executed. it's called with 2 params: 'records' array and 'actionParameter'
      actionFunction: PropTypes.func.isRequired,

      // readable name of the 'actionParameter'
      actionParameterLabel: PropTypes.string,
      // type of parameter that can be sent to the 'actionFunction' - should be one of ACTION_PARAMETER_TYPES
      actionParameterType: PropTypes.string.isRequired,
      // if 'actionParameterType' is set to ACTION_PARAMETER_TYPES.OPTIONS_LIST, this array is used as a list of selectable options
      actionParameterListOptions: PropTypes.arrayOf(
        PropTypes.shape({
          value: PropTypes.string,
          label: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
        })
      ),

      // optional function that takes 'records' array and 'actionParameter' as params and returns true/false. if it returns true, then the action execution is prevented
      isDisabled: PropTypes.func
    })
  ).isRequired

}
BatchActions.defaultProps = {}
BatchActions.ACTION_PARAMETER_TYPES = ACTION_PARAMETER_TYPES

export default connect(BatchActions)
