import store from '../store'
import setWorkspaceId from '../action/set-workspace-id'
import setCalendarFilters from '../action/set-calendar-filters'
import {
  miscUtil,
  permissionsUtil,
  momentUtil
} from '@app/util'
import isLoading from './is-loading'
import loadWorkspaceDetail from '../ac/load-workspace-detail'
import moment from 'moment'
import { PERMISSIONS } from '@app/const'

export default (wsIdPar, options = {}) => {
  return async (dispatch) => {
    const { workspaces, workspaceId, me, organization } = store.getState()
    let wsId = wsIdPar

    // if the auto-selection function is enabled and specific wsId isn't passed
    if (options?.autoSelect) {
      // if no workspace ID is passed preload it from the store.workspaceId
      if (workspaceId) {
        // we can use store.workspaceId if it points to one of our workspaces
        if (workspaces.some(ws => ws.id === workspaceId)) wsId = workspaceId
        // or we can use store.workspaceId if we're in PRO interface and it's among our store.organization.allAllowedWorkspaces
        if (!wsId && miscUtil.isDayswapsProInterface()) {
          if (organization?.allAllowedWorkspaces?.includes(workspaceId)) wsId = workspaceId
        }
      }

      // if no workspace ID is preloaded from the store
      // pick the first one from user workspaces
      if (!wsId && workspaces?.length) {
        wsId = workspaces[0].id
      }
    }

    // if wsId is still not set by now,
    // we set workspaceId to null and do nothing else
    if (!wsId) {
      // set workspaceId to null in store
      dispatch(setWorkspaceId(null))
      // and end this function
      return
    }

    // if we're in DS PRO interface, we don't want to do most
    // of the usual WS switching logic. we just change the store.workspaceId,
    // load WS details and stop.
    if (miscUtil.isDayswapsProInterface()) {
      if (wsId && wsId !== workspaceId) {
        await dispatch(setWorkspaceId(wsId))
        await dispatch(loadWorkspaceDetail(wsId, Boolean(options?.silent)))
      }
      return
    }

    // if we're changing the wsId to something else than we had in store,
    // the calendar filters will need to be reset (because they were filters
    // for the previous workspace)
    let calendarFiltersResetNeeded = false
    if (workspaceId && wsId !== workspaceId) {
      calendarFiltersResetNeeded = true
    }

    if (!options?.silent) await dispatch(isLoading('workspaces'))
    if (!options?.silent) await dispatch(isLoading('setting-timezone'))

    // user has no workspace with this ID
    let activeWs = workspaces && workspaces.find((ws) => ws.id === wsId)
    if (!activeWs) {
      // clear non existent ws id from store
      dispatch(setWorkspaceId(null))
      if (!options?.silent) await dispatch(isLoading('setting-timezone', true))
      if (!options?.silent) await dispatch(isLoading('workspaces', true))
      return
    }

    // reset calendar filters
    if (calendarFiltersResetNeeded || (options && options.resetCalendarFilters)) {
      await dispatch(setCalendarFilters([]))
    }

    // load the WS details asynchronously and store them
    dispatch(loadWorkspaceDetail(wsId)).then((resultWS) => {
      if (!resultWS.error) activeWs = resultWS

      // and set the time zone
      momentUtil.setTimezone(activeWs.timezone)

      // update the window.trackingData object - this is accessed by various 3rd party tracking/marketing/analytics tools
      window.trackingData.workspaceId = resultWS.id
      window.trackingData.workspaceName = resultWS.name
      window.trackingData.workspaceCreated = parseInt(moment(resultWS.created).format('X'))
      window.trackingData.hasCalendarWriteAccess = (permissionsUtil.canWrite(PERMISSIONS.CALENDAR))
      window.trackingData.plugins = resultWS.plugins?.filter(p => p.enabled).map(p => p.plugin)

      if (resultWS.organization) {
        window.trackingData.organizationId = resultWS.organization.id
        window.trackingData.organizationName = resultWS.organization.name
      }

      const roleOnSelectedWs = me.roles?.find(r => r.workspaceId === wsId)
      if (roleOnSelectedWs) {
        if (roleOnSelectedWs.contracts && roleOnSelectedWs.contracts.length) {
          let mostRecentContract = roleOnSelectedWs.contracts[0]
          roleOnSelectedWs.contracts.forEach(c => {
            if (!c.period?.end || (mostRecentContract?.period?.end && moment(c?.period?.end).isAfter(mostRecentContract?.period?.end))) mostRecentContract = c
          })
          window.trackingData.contractEnds = moment(mostRecentContract?.period?.end).isValid() ? parseInt(moment(mostRecentContract?.period?.end).format('X')) : parseInt(moment('3000-01-01', 'YYYY-MM-DD').format('X'))
        }
      }
    })

    await dispatch(setWorkspaceId(wsId))
    if (!options?.silent) await dispatch(isLoading('setting-timezone', true))
    if (!options?.silent) await dispatch(isLoading('workspaces', true))
  }
}
