import React from 'react'
import { t } from 'i18next'
import {
  Alert,
  Dropdown,
  Flex,
  RadioGroup,
  Spacing
} from '@ui'
import { isDefined, isNotNull, miscUtil } from '@app/util'

import moment from 'moment'
import { TimeInput } from '@app/component/ui/input/time-input/time-input'

export const IncludeTimeOffInSelectedPeriod = ({
  isMultipleDays,
  setVal,
  workMinutesBy,
  workMinutes,
  inclusion,
  selectedCategory,
  employee,
  contractId,
  endDate,
  disabledEditation,
  halfDay,
  organizationId
}) => {
  const includedHoursOptions = [
    miscUtil.getFilteredContentForOrganization('time_off_edit_include_hour_option_contract', {
      label: t('BY_CONTRACT'),
      value: 'contract'
    }),
    {
      label: t('BY_REPLACED_SHIFT'),
      value: 'shift'
    },
    {
      label: t('CUSTOM'),
      value: 'custom'
    },
    miscUtil.getFilteredContentForOrganization('time_off_edit_include_hour_option_settings', {
      label: t('BY_TIMEOFF_SETTING'),
      value: 'settings'
    })
  ].filter(Boolean)

  const radioOptions = [
    {
      label: t('DAYS_WITH_PLANNED_SHIFT'),
      value: 'shiftDays'
    },
    {
      label: t('EVERY_WORK_DAY'),
      value: 'workDays'
    },
    {
      label: t('EVERY_DAY'),
      value: 'everyDay'
    }
  ]
  const desiredContract = employee?.contracts?.find((con) => con.id === contractId)
  const contractOptions = desiredContract?.options
  const contractEnd = desiredContract?.period?.end

  const isSelectedCategoryHoursCustom =
    isDefined(selectedCategory?.maxDailyMinutes) &&
    isNotNull(selectedCategory?.maxDailyMinutes)

  const hasDailyContractMinutes =
    isDefined(contractOptions?.minutesOfWorkPerShift) &&
    isNotNull(contractOptions?.minutesOfWorkPerShift)

  const showFieldForSettings = workMinutesBy === 'settings' && isSelectedCategoryHoursCustom
  const showFieldForCustom = workMinutesBy === 'custom'
  const showFieldForContract = workMinutesBy === 'contract' && hasDailyContractMinutes

  const showField = showFieldForSettings || showFieldForCustom || showFieldForContract

  const decideCounted = () => {
    const contractAmount = contractOptions?.minutesOfWorkPerShift / 60 || 0

    if (workMinutesBy === 'settings') {
      if (selectedCategory?.maxDailyMinutes) {
        return selectedCategory?.maxDailyMinutes / 60
      } else {
        return contractAmount
      }
    }
    if (workMinutesBy === 'contract') {
      return contractAmount
    }
    return null
  }

  return (
    <>
      <RadioGroup
        label={t('TIMEOFF_INCLUDE')}
        options={radioOptions}
        disabled={!isMultipleDays || disabledEditation}
        onChange={(value) => {
          setVal('workMinutesInclusion', value)
          window.localStorage.setItem('ds-selected-unavailability-work-minutes-inclusion', value)
        }}
        value={inclusion}
      />
      {!halfDay && (
        <>
          <Spacing size={Spacing.SIZES.SIZE_20} grow={1} />
          <Flex align={Flex.POSITION.END}>
            <div style={{ width: '50%' }}>
              <Dropdown
                style={Dropdown.STYLES.LIGHT}
                type={Dropdown.TYPES.VARIABLE}
                size={Dropdown.SIZES.FULL_WIDTH}
                singleSelect
                label={t('WS_SETTINGS_CARD_UNAV_CAT_HOURS')}
                options={includedHoursOptions}
                onChange={({ value }) => setVal('workMinutesBy', value)}
                value={includedHoursOptions.find((option) => option.value === workMinutesBy)}
                disabled={disabledEditation}
              />
            </div>
            <Spacing size={Spacing.SIZES.SIZE_6} type={Spacing.TYPES.HORIZONTAL} />
            {showField && (
              <div style={{ width: '50%' }}>
                <TimeInput
                  disabled={workMinutesBy !== 'custom' || disabledEditation}
                  value={(workMinutesBy === 'custom') ? workMinutes : decideCounted() * 60}
                  onChange={(mins) => {
                    setVal('workMinutes', mins)
                  }}
                />
              </div>
            )}
          </Flex>
        </>)}
      {contractEnd && moment(contractEnd).isBefore(endDate) && (
        <>
          <Spacing size={Spacing.SIZES.SIZE_6} />
          <Alert
            text={t('EMPLOYEE_WARNINGS.contractExpire.desc', { x: moment(contractEnd).format('DD.MM.YYYY') })}
            type={Alert.TYPES.ERROR}
            size={Alert.SIZES.FULL_WIDTH}
          />
        </>
      )}
    </>
  )
}

IncludeTimeOffInSelectedPeriod.propTypes = {}
IncludeTimeOffInSelectedPeriod.defaultProps = {}
