import React, { useState } from 'react'
import { t } from 'i18next'

import {
  Button,
  Dropdown,
  Input,
  Modal,
  Text,
  Flex,
  Spacing
} from '@ui'
import { requestOrgBonusSettingsUpdate } from '@app/request'

import connect from './connect'
import { SIZES, SIZE_WIDTH_MAP } from '@app/component/ui/modal/Modal'
import { MealVoucherCalculationType } from '@app/gql-types/graphql'
import { useRootSelector } from '@app/store'

export const MealTicketRule = connect(({
  conditionOptions,
  contractTypeOptions,
  calculationTypeOptions,
  rules,
  getOrganization,
  organizationId,
  country,
  auth,
  setModal,
  isEdit,
  formValues
}) => {
  const organization = useRootSelector(state => state.organization)
  const [form, setForm] = useState({
    contractType: formValues?.contractType ?? null,
    calculationType: formValues?.calculationType ?? null,
    shiftDurationMinutes: formValues?.shiftDurationMinutes ?? null,
    condition: formValues?.condition ?? null,
    amount: formValues?.amount ?? null
  })
  const handleCreate = () => {
    requestOrgBonusSettingsUpdate({
      countryUpdateOrganization2: organizationId,
      countryUpdateCountry2: country,
      data: {
        bonuses: {
          sales: undefined,
          inventory: undefined,
          mealVouchers: {
            rules: [
              ...rules, // in case of edit this gets pre-filtered to remove currently edited rule
              {
                ...form,
                contractType: form.contractType.value,
                calculationType: form.calculationType.value,
                shiftDurationMinutes: form.shiftDurationMinutes * 60,
                condition: form.condition?.value || null,
                amount: form.amount
              }
            ]
          }
        }
      }
    }, auth).then(() => {
      getOrganization(false)
      setModal(null)
    })
  }
  return (
    <Modal
      size={form.calculationType?.value ? Modal.SIZES.L : Modal.SIZES.M}
      headerContent={
        <Text weight={Text.WEIGHTS.BOLD}>
          {isEdit ? t('EDIT_RULE') : t('NEW_RULE')}
        </Text>
      }
      footerContent={
        <Flex justify={Flex.POSITION.END} stretch>
          <Button
            label={isEdit ? t('SAVE') : t('CREATE')}
            onClick={handleCreate}
            style={Button.STYLES.CONTAINED}
          />
        </Flex>
      }
    >
      <Spacing size={Spacing.SIZES.SIZE_16}>
        <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.HORIZONTAL}>
          <div style={{ display: 'flex', flexDirection: 'column', maxWidth: SIZE_WIDTH_MAP[SIZES.M], flexWrap: 'wrap', gap: '0.5rem' }}>
            <Dropdown
              options={calculationTypeOptions}
              style={Dropdown.STYLES.LIGHT}
              type={Dropdown.TYPES.VARIABLE}
              size='full-width'
              placeholder={t('CHOOSE')}
              singleSelect
              label={t('CALCULATIONTYPE')}
              onChange={(v) => {
                setForm({ ...form, calculationType: v, amount: null, shiftDurationMinutes: null, condition: null })
              }}
              value={form.calculationType}
              disabled={isEdit}
            />
            <Dropdown
              options={contractTypeOptions}
              style={Dropdown.STYLES.LIGHT}
              type={Dropdown.TYPES.VARIABLE}
              size='full-width'
              placeholder={t('CHOOSE')}
              singleSelect
              label={t('ORG_BONUSES_MEAL_TICKETS_EMPLOYEE')}
              onChange={(v) => setForm({ ...form, contractType: v })}
              value={form.contractType}
            />
          </div>
          {form.calculationType?.value === MealVoucherCalculationType.ShiftLength && (
            <>
              <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.VERTICAL}>
                <Flex align={Flex.POSITION.CENTER}>
                  <Text type={Text.TYPES.BODY_MEDIUM} weight={Text.WEIGHTS.BOLD}>{t('ORG_BONUSES_MEAL_TICKETS_AT_SHIFT')}</Text>
                  <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.HORIZONTAL} />
                  <Dropdown
                    options={conditionOptions}
                    style={Dropdown.STYLES.LIGHT}
                    type={Dropdown.TYPES.VARIABLE}
                    placeholder={t('CHOOSE')}
                    singleSelect
                    onChange={(v) => setForm({
                      ...form,
                      condition: v
                    })}
                    value={form.condition}
                  />
                  <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.HORIZONTAL}>
                    <Input
                      onChange={(v) => setForm({
                        ...form,
                        shiftDurationMinutes: parseInt(v)
                      })}
                      value={form.shiftDurationMinutes}
                      placeholder='2'
                      type='number'
                    />
                  </Spacing>
                  <Text color={Text.COLORS.PRIMARY}>hod</Text>
                </Flex>
              </Spacing>
              <Flex align={Flex.POSITION.CENTER}>
                <Text type={Text.TYPES.BODY_MEDIUM} weight={Text.WEIGHTS.BOLD}>{t('ORG_BONUSES_MEAL_TICKETS_EARNS')}</Text>
                <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.HORIZONTAL} />
                <Input
                  onChange={(v) => setForm({
                    ...form,
                    amount: parseFloat(v)
                  })}
                  value={form.amount}
                  placeholder='10'
                  type='number'
                  extraContent={
                    <Text color={Text.COLORS.PRIMARY}>{organization.countries.at(0)?.currency}</Text>
                  }
                />
              </Flex>
            </>
          )}
          {form.calculationType?.value === MealVoucherCalculationType.Fixed && (
            <>
              <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.VERTICAL}>
                <Flex align={Flex.POSITION.CENTER}>
                  <Text type={Text.TYPES.BODY_MEDIUM} weight={Text.WEIGHTS.BOLD}>{t('FIXED_CONDITION')}</Text>
                  <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.HORIZONTAL} />
                  <Input
                    onChange={(v) => setForm({
                      ...form,
                      shiftDurationMinutes: parseInt(v)
                    })}
                    value={form.shiftDurationMinutes}
                    placeholder='2'
                    type='number'
                    extraContent={
                      <Text color={Text.COLORS.PRIMARY}>{t('HOUR_SHORT')}</Text>
                    }
                  />
                </Flex>
              </Spacing>
              <Flex align={Flex.POSITION.CENTER}>
                <Text type={Text.TYPES.BODY_MEDIUM} weight={Text.WEIGHTS.BOLD}>{t('FIXED_REWARD')}</Text>
                <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.HORIZONTAL} />
                <Input
                  onChange={(v) => setForm({
                    ...form,
                    amount: parseFloat(v)
                  })}
                  value={form.amount}
                  placeholder='10'
                  type='number'
                  extraContent={
                    <Text color={Text.COLORS.PRIMARY}>{organization.countries.at(0)?.currency}</Text>
                  }
                />
              </Flex>
            </>
          )}
          {form.calculationType?.value === MealVoucherCalculationType.Pieces && (
            <>
              <Spacing size={Spacing.SIZES.SIZE_16} type={Spacing.TYPES.VERTICAL}>
                <Flex align={Flex.POSITION.CENTER}>
                  <Text type={Text.TYPES.BODY_MEDIUM} weight={Text.WEIGHTS.BOLD}>{t('ORG_BONUSES_GETS')} {t('ORG_BONUSES_REWARD')} {t('ORG_BONUSES_MEAL_TICKETS_AT_SHIFT')}</Text>
                  <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.HORIZONTAL} />
                  <Dropdown
                    options={conditionOptions}
                    style={Dropdown.STYLES.LIGHT}
                    type={Dropdown.TYPES.VARIABLE}
                    placeholder={t('CHOOSE')}
                    singleSelect
                    onChange={(v) => setForm({
                      ...form,
                      condition: v
                    })}
                    value={form.condition}
                  />
                  <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.HORIZONTAL} />
                  <Input
                    onChange={(v) => setForm({
                      ...form,
                      shiftDurationMinutes: parseInt(v)
                    })}
                    value={form.shiftDurationMinutes}
                    placeholder='2'
                    type='number'
                    extraContent={
                      <Text color={Text.COLORS.PRIMARY}>{t('HOUR_SHORT')}</Text>
                    }
                  />
                </Flex>
              </Spacing>
              <Flex align={Flex.POSITION.CENTER}>
                <Text type={Text.TYPES.BODY_MEDIUM} weight={Text.WEIGHTS.BOLD}>{t('ORG_BONUSES_OF_VALUE')}</Text>
                <Spacing size={Spacing.SIZES.SIZE_8} type={Spacing.TYPES.HORIZONTAL} />
                <Input
                  onChange={(v) => setForm({
                    ...form,
                    amount: parseFloat(v)
                  })}
                  value={form.amount}
                  placeholder='10'
                  type='number'
                  extraContent={
                    <Text color={Text.COLORS.PRIMARY}>{t('ORG_BONUSES_MEAL_TICKETS')}</Text>
                  }
                />
              </Flex>
            </>
          )}
        </Spacing>
      </Spacing>
    </Modal>
  )
})

MealTicketRule.propTypes = {}
MealTicketRule.defaultProps = {}
