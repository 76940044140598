const calcStickyProperties = (cellIndex, rowCells = []) => {
  const previousCell = cellIndex > 0 && rowCells[cellIndex - 1]
  const isPreviousSticky = previousCell?.column?.sticky || previousCell?.sticky
  const nextCell = rowCells[cellIndex + 1]
  const isNextSticky = nextCell?.column?.sticky || nextCell?.sticky
  const isPreviousSelection = previousCell?.column?.id === 'selection' || previousCell?.id === 'selection'
  const isLast = rowCells?.length === cellIndex + 1
  const stickyOffset = () => {
    if (isPreviousSticky || isPreviousSelection) {
      return rowCells
        .slice(0, cellIndex)
        .reduce((acc, curr) => {
          return acc + (curr.column?.width ?? curr.width)
        }, 0)
    }
  }
  return {
    isLast,
    previousCell,
    isPreviousSticky,
    isNextSticky,
    stickyOffset: stickyOffset()
  }
}

const handleScrollShadows = (e, tableRef) => {
  const target = e.target
  if (target.scrollLeft > 0) {
    const selectors = tableRef.current.querySelectorAll('.with-shadow-detection')
    selectors.forEach((selector) => selector.classList.add('with-shadow'))
  } else {
    const selectors = tableRef.current.querySelectorAll('.with-shadow-detection')
    selectors.forEach((selector) => selector.classList.remove('with-shadow'))
  }
  if (target.scrollWidth - target.scrollLeft - target.getBoundingClientRect().width > 0) {
    const selectors = tableRef.current.querySelectorAll('.last-sticky-detection')
    selectors.forEach((selector) => selector.classList.add('last-sticky'))
  } else {
    const selectors = tableRef.current.querySelectorAll('.last-sticky-detection')
    selectors.forEach((selector) => selector.classList.remove('last-sticky'))
  }
}

export {
  calcStickyProperties,
  handleScrollShadows
}
