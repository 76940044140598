import { bindActionCreators, compose } from 'redux'
import { connect } from 'react-redux'
import { withPlugin } from '@core/hoc/withPlugin'
import setCalendar from '@app/ac/set-calendar'
import setCalendarFilters from '@app/ac/set-calendar-filters'
import setSidebar from '@app/ac/set-sidebar'
import setOffers from '@app/action/set-offers'
import loadWorkspaceEvents from '@app/ac/load-workspace-events'
import { loadRoleStatsMulti, setModal } from '@app/ac'

const mapStateToProps = ({ calendar, calendarFilters, sidebar, shifts, availabilities, timeOffs, employees, offers, positions, workspaces, workspaceId, auth, userId, modal }) => {
  const ws = workspaces && workspaceId ? workspaces.find(ws => ws.id === workspaceId) : null
  return {
    auth,
    sidebar,
    calendar: calendar || {},
    calendarFilters,
    shifts,
    availabilities,
    timeOffs,
    offers,
    employees,
    positions,
    workspace: ws,
    userId,
    modal
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({
    setCalendar,
    setCalendarFilters,
    setSidebar,
    loadWorkspaceEvents,
    loadRoleStatsMulti,
    setOffers,
    setModal
  }, dispatch)
}

export default (component) => {
  return compose(
    withPlugin,
    connect(mapStateToProps, mapDispatchToProps))(component)
}
